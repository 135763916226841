import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Notification } from '@shared/models/notification.model';

export interface MessagesState extends EntityState<Notification> {
  loading: [];
}

export const selectId = ({ id }: Notification) => id;

export const sortComparer = (a: Notification, b: Notification): number =>
  a.created_at.toString().localeCompare(b.created_at.toString());

export const adapter: EntityAdapter<Notification> = createEntityAdapter(
{ selectId, sortComparer }
);

export const initialState: MessagesState = adapter.getInitialState(
{ loading: [] }
);
