import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@core/authentication/interface/user.interface';

export interface UserState extends EntityState<User> {
  loading: [];
}

export const selectId = ({ id }: User) => id ? id : 0;

export const sortComparer = (a: User, b: User): number =>
  a.updated_at!.toString().localeCompare(b.updated_at!.toString());

export const adapter: EntityAdapter<User> = createEntityAdapter(
{ selectId, sortComparer }
);

export const initialState: UserState = adapter.getInitialState(
{ loading: [] }
);
