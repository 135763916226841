import { createAction, props } from '@ngrx/store';
import { Company } from '@shared/models/company.model';

export const companysKey = '[Companys]';

export const addCompany = createAction(
  `${companysKey} Add Company`,
  props<{ company: Company }>()
);

export const deleteCompany = createAction(
  `${companysKey} Delete Company`,
  props<{ id: number }>()
);

export const deleteCompanySuccess = createAction(
`${companysKey} Delete Company Success`
);

export const deleteCompanyError = createAction(
`${companysKey} Delete Company Error`
);
