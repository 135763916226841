import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Company } from '@shared/models/company.model';
import { addCompany, deleteCompany } from './companys.actions';
import { selectCompanys } from './companys.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanysFacade {
  private readonly store: Store = inject(Store);

  readonly companys$: Observable<Company[]> = this.store.select(selectCompanys);

  addCompany(company: Company): void {
    this.store.dispatch(addCompany({ company }));
  }
  deleteCompany(id: number): void {
    this.store.dispatch(deleteCompany({ id }));
  }
}
