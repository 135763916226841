import { ActionReducer, createReducer, on } from '@ngrx/store';
import { adapter, initialState, MessagesState } from './messages.state';
import { addMessage, deleteMessage, markReadNotify, updateMessage } from './messages.actions';
import { loadMessagesSuccess } from './messages.actions';

export const messagesReducers: ActionReducer<MessagesState> = createReducer(
  initialState,
  on(addMessage, (state: MessagesState, { message }) =>
    adapter.addOne(message, state)),
  on(deleteMessage, (state: MessagesState, { id }) =>
    adapter.removeOne(id, state)),
  on(updateMessage, (state: MessagesState, { message }) => {
    const updatedMessage = { ...message, read: true };
    return adapter.updateOne({ id: updatedMessage.id, changes: updatedMessage }, state);
  }),
  on(markReadNotify, (state: MessagesState, { message }) => {
    const updatedMessage = { ...message, read: true };
    return adapter.updateOne({ id: updatedMessage.id, changes: updatedMessage }, state);
  }),
  on(loadMessagesSuccess, (state, { messages }) => adapter.setAll(messages, state))
);
