import { AppState } from '../index';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { CompanysState } from './companys.state';
import { Company } from '@shared/models/company.model';

export const selectCompanysFeature: MemoizedSelector<AppState, CompanysState> =
  createFeatureSelector<CompanysState>('companys');


export const selectCompanys: MemoizedSelector<AppState, Company[]> =
createSelector(
  selectCompanysFeature,
  ({ entities }: CompanysState): Company[] =>
    (Object.values(entities) as Company[])
);
