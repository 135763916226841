import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { addCompany, deleteCompany, deleteCompanyError, deleteCompanySuccess } from './companys.actions';
import { CompanyService } from '@shared/services/company.service';
import { catchError, map, mergeMap } from 'rxjs';


export const deleteCompany$ = createEffect(
  (actions$: Actions = inject(Actions), apiService: CompanyService = inject(CompanyService)) => {
    return actions$.pipe(
      ofType(deleteCompany),
      mergeMap(({ id }) =>
          apiService.delete(id).pipe(
            map(() => deleteCompanySuccess()),
            catchError(() => [deleteCompanyError()])
        )
      )
    );
  },
  { functional: true }
);

export const getCompany$ = createEffect(
  (actions$: Actions = inject(Actions), apiService: CompanyService = inject(CompanyService)) => {
    return actions$.pipe(
      ofType(addCompany),
      mergeMap(({ company }) =>
          apiService.get(company.id).pipe(
            map(() => deleteCompanySuccess()),
            catchError(() => [deleteCompanyError()])
        )
      )
    );
  },
  { functional: true }
);

