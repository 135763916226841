import { AppState } from '../index';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { MessagesState } from './messages.state';
import { Notification } from '@shared/models/notification.model';

export const selectMessagesFeature: MemoizedSelector<AppState, MessagesState> =
  createFeatureSelector<MessagesState>('messages');

export const selectAppState = createFeatureSelector<AppState>('messages');

export const selectMessages: MemoizedSelector<AppState, Notification[]> =
createSelector(
  selectMessagesFeature,
  ({ entities }: MessagesState): Notification[] =>
    Object.values(entities) as Notification[]
);

export const selectProducts = createSelector(
  selectAppState,
  (state) => {
    return state.messages
  }
);

// Seletor para retornar uma notificação específica pelo ID
export const selectNotificationById = (notificationId: number) =>
  createSelector(
    selectMessages,
    (notifications: Notification[]) =>
      notifications.find(notification => notification.id === notificationId)
  );
