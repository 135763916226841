import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Notification } from '@shared/models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private readonly http: HttpClient = inject(HttpClient);
  private url = `${ environment.APIbaseUrl }/notificacao`;

  deleteMessage(id: string): Observable<void> {
    return this.http.delete<void>(`/${id}`);
  }

  markRead(id: number) {
    return this.http.patch<void>(`${ this.url }/marcarlida/${id}`, []);
  }

  getAll() {
    return this.http.get<Notification[]>(`${ this.url }`);
  }
}
