import { ActionReducer, createReducer, on } from '@ngrx/store';
import { adapter, initialState, CompanysState } from './companys.state';
import { addCompany, deleteCompany } from './companys.actions';

export const companysReducers: ActionReducer<CompanysState> = createReducer(
  initialState,
  on(addCompany, (state: CompanysState, { company }) =>
    adapter.addOne(company, state)),
  on(deleteCompany, (state: CompanysState, { id }) =>
    adapter.removeOne(id, state))
);
