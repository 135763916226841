import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@core/authentication/interface/user.interface';
import { addUser, deleteUser } from './users.actions';
import { selectUsers } from './users.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersFacade {
  private readonly store: Store = inject(Store);

  readonly Users$: Observable<User[]> = this.store.select(selectUsers);

  addUser(User: User): void {
    this.store.dispatch(addUser({ User }));
  }
  deleteUser(id: number): void {
    this.store.dispatch(deleteUser({ id }));
  }
}
