import { ActionReducer, createReducer, on } from '@ngrx/store';
import { adapter, initialState, UserState } from './users.state';
import { addUser, deleteUser } from './users.actions';

export const UsersReducers: ActionReducer<UserState> = createReducer(
  initialState,
  on(addUser, (state: UserState, { User }) =>
    adapter.addOne(User, state)),
  on(deleteUser, (state: UserState, { id }) =>
    adapter.removeOne(id, state))
);
