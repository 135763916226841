import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Company } from '@shared/models/company.model';

export interface CompanysState extends EntityState<Company> {
  loading: [];
}

export const selectId = ({ id }: Company) => id ? id : 0;

export const sortComparer = (a: Company, b: Company): number =>
  a.updated_at!.toString().localeCompare(b.updated_at!.toString());

export const adapter: EntityAdapter<Company> = createEntityAdapter(
{ selectId, sortComparer }
);

export const initialState: CompanysState = adapter.getInitialState(
{ loading: [] }
);
