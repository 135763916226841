import { AppState } from '../index';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserState } from './users.state';
import { User } from '@core/authentication/interface/user.interface';

export const selectUsersFeature: MemoizedSelector<AppState, UserState> =
  createFeatureSelector<UserState>('Users');

export const selectUsers: MemoizedSelector<AppState, User[]> =
createSelector(
  selectUsersFeature,
  ({ entities }: UserState): User[] =>
    Object.values(entities) as User[]
);
