import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Notification } from '@shared/models/notification.model';
import { addMessage, deleteMessage, loadMessages, markReadNotify, updateMessage } from './messages.actions';
import { selectMessages } from './messages.selectors';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessagesFacade {

  private readonly store: Store = inject(Store);

  readonly messages$: Observable<Notification[]> = this.store.select(selectMessages);

  addMessage(message: Notification): void {
    this.store.dispatch(addMessage({ message }));
  }
  deleteOne(id: string): void {
    this.store.dispatch(deleteMessage({ id }));
  }
  updateMessage(message: Notification): void {
    this.store.dispatch(updateMessage({ message }));
  }
  markReadNotify(message: Notification): void {
    this.store.dispatch(markReadNotify({ message }));
  }
  loadMessages(): void {
    this.store.dispatch(loadMessages());
  }
}
