import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Company } from '@shared/models/company.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(protected http: HttpClient) {}
  private url = `${ environment.APIbaseUrl }/empresa`;

  get(id: number): Observable<Company> {
    console.log('exec get')
    return this.http.get<Company>(`${ this.url }/${id}`);
  }

  post(company: Company) {
    return this.http.post<Company>(`${ this.url }`, company);
  }

  put(id: number, company: Company) {
    return this.http.put<any>(`${ this.url }/${id}`, company);
  }

  uploadImage(id: number, file: File) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('id', id.toString());
    return this.http.post<any>(`${ this.url }/uploadlogo`, formData);
  }

  delete(id: number) {
    return this.http.delete<void>(`${ this.url }/${id}`);
  }

}
