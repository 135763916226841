import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Menu } from '@core';
import { Token } from './interface';
import { User } from './interface/user.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(protected http: HttpClient) {}
  private url = environment.APIbaseUrl;



  login(email: string, password: string, rememberMe = false) {
    return this.http.post<User>(this.url + '/login', { email, password, rememberMe });
  }

  refresh(params: Record<string, any>) {
    return this.http.post<Token>(this.url + '/refresh', params);
  }

  logout() {
    return this.http.post<any>(this.url + '/logout', {});
  }

  details() {
    return this.http.get<User>(this.url + '/details');
  }

  menu() {
    return this.http.get<{ menu: Menu[] }>(this.url + '/menu')
      .pipe(map(
        res => {
          return res.menu;  }
        ));
  }
}
