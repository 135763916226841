import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadMessages, loadMessagesSuccess, markReadMessagesSuccess, markReadNotify, messagesKey } from './messages.actions';
import { NotificationService } from '../../shared/services/notification.service';
import { EMPTY, catchError, map, mergeMap } from 'rxjs';

@Injectable()
export class MessagesEffects {

  loadMessages$ = createEffect(() => this.actions$.pipe(
    ofType(loadMessages),
    mergeMap(() => this.dataService.getAll()
      .pipe(
        map(messages => loadMessagesSuccess({ messages })),
        catchError(() => EMPTY)
      ))
    )
  );

  markAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(markReadNotify),
    mergeMap(({ message }) => this.dataService.markRead(message.id)
      .pipe(
        map(messages => markReadMessagesSuccess()),
        catchError(() => EMPTY)
      ))
    )
  );


  constructor(
    private actions$: Actions,
    private dataService: NotificationService
  ) {}
}

/*
export const markRead$ = createEffect(
  (actions$: Actions = inject(Actions), messagesApiService: MessagesApiService = inject(MessagesApiService)) => {
    return actions$.pipe(
      ofType(markReadNotify),
      mergeMap(({ message }) =>

        messagesApiService.markRead(message.id).pipe(
          map(() => deleteMessageSuccess()),
          catchError(() => [deleteMessageError()])
        )
      )
    );
  },
  { functional: true }
)*/
