import { createAction, props } from '@ngrx/store';
import { Notification } from '@shared/models/notification.model';

export const messagesKey = '[Messages]';

export const addMessage = createAction(
  `${messagesKey} Add Message`,
  props<{ message: Notification }>()
);

export const deleteMessage = createAction(
  `${messagesKey} Delete Message`,
  props<{ id: string }>()
);

export const updateMessage = createAction(
  `${messagesKey} Update Message`,
  props<{ message: Notification }>()
);

export const deleteMessageSuccess = createAction(
`${messagesKey} Delete Message Success`
);

export const deleteMessageError = createAction(
`${messagesKey} Delete Message Error`
);

export const markReadNotify = createAction(
  `${messagesKey} Mark Read Message`,
  props<{ message: Notification }>()
);

export const loadMessages = createAction(
  `${messagesKey} Load Messages`
);

export const loadMessagesSuccess = createAction(
  `${messagesKey} Load Messages Sucess`,
  props<{ messages: Notification[] }>()
);

export const markReadMessagesSuccess = createAction(
  `${messagesKey} Mark Read Message Sucess`
);

